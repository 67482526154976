import { ClientService, RequestMethod } from "../client-service";
import { RequestResponseLogic } from "../Ui/request-response";

const SEND_NOTI = "/notification/send";
const SEND_TEMPLATE = "/notification/send/template";
const SENT_NOTI = "/notification/sent";

export const NotificationLogic = {
  async sendNotification(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      SEND_NOTI,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async sendTemplate(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      SEND_TEMPLATE,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
    async getSentNotification(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      SENT_NOTI,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
};
