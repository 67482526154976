<template>
  <div style="margin: 10px">
    <div class="eh-flex-container">
      <!-- <fp-input
          class="eh-margin-right"
          :label="$t('base.basic.filter')"
          @change="filterList"
          v-model="filter"
        /> -->
      <fp-date-range-button
        :label="$t('partner.participantList.communicationTab.sendTime')"
        v-model="addDateFilter"
        @change="filterList"
      />
      <!-- Hívás -->
      <b-button class="eh-action-button">
        {{ $t("partner.participantList.communicationTab.call") }}
      </b-button>
      <!-- Videó hívás -->
      <!-- TODO: szótárazni -->
      <b-button class="eh-action-button">
        Videó hívás
      </b-button>
      <!-- Üzenet küldés -->
      <b-button class="eh-action-button" @click="openTemplate = true">
        {{ $t("partner.participantList.communicationTab.sendMessages") }}
      </b-button>
      <template-list
        v-model="openTemplate"
        :participantId="participant.UserId"
        :project="project"
        @refreshList="getSentNotifications"
      />
    </div>

    <!-- Napló lista -->
    <fp-table-list :items="filteredCommunicationHistory"
                   :fields="communicationHistoryFields"
                   sortBy="CreatedAt"
                   :sortDesc="true">
        <!--<template #ID="row">
            {{ row.item.NotificationId }}
        </template>-->

        <!--<template #Resend="row">
            <b-button class="eh-action-button">
                {{ $t("partner.participantList.communicationTab.sendMessages") }}
            </b-button>

        </template>-->
        <template #Types="row">
            <b-icon style="margin-right:5px"
                    v-if="row.item.Parts.find((part) => part.TargetId == participant.UserId).Email" icon="envelope-fill"
                    :variant="getStatus(row.item.Parts.find((part) => part.TargetId == participant.UserId).Email)">
            </b-icon>
            <b-icon style="margin-right:5px"
                    v-if="row.item.Parts.find((part) => part.TargetId == participant.UserId).Sms" icon="chat-square-text-fill"
                    :variant="getStatus(row.item.Parts.find((part) => part.TargetId == participant.UserId).Sms)">
            </b-icon>
            <b-icon style="margin-right:5px"
                    v-if="row.item.Parts.find((part) => part.TargetId == participant.UserId).Push" icon="phone-vibrate"
                    :variant="getStatus(row.item.Parts.find((part) => part.TargetId == participant.UserId).Push)">
            </b-icon>
        </template>
        <template #CreatedAt="row"> {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }} </template>

        <template #Operations="row">
            <b-icon class="h4"
                    style="cursor: pointer;margin-right:5px"
                    icon="eye-fill"
                    @click="$set(row.item, '_showDetails', !row.item._showDetails)" />
        </template>

        <template #row-details="row">
            <!-- Email sablon -->
            <fp-panel-card :closedCard="true" v-if="row.item.Parts.find((part)=> part.TargetId == participant.UserId).Email"
                style="margin: 10px 0px"
                :title="$t('partner.participantList.communicationTab.email')">

                <!-- Típus -->
                <template #title>
                    <b-icon style="margin: 0px 5px" icon="envelope-fill"></b-icon>
                    {{ $t("partner.participantList.communicationTab.email") }}
                </template>

                <!-- Adatok -->
                <template #content>
                    <div class="contenttext" 
                         v-if="row.item.Parts.find((part) => part.TargetId == participant.UserId).Email.Result.Code != 0"
                         v-html="row.item.Parts.find((part) => part.TargetId == participant.UserId).Email.Result.Message" />
                    <!-- Tárgy -->
                    <fp-input style="margin: 10px 0px"
                              :label="$t('partner.participantList.communicationTab.emailSubject')"
                              :disabled="true"
                              v-model="row.item.Parts.find((part) => part.TargetId == participant.UserId).Email.Subject" />
                    <div class="content" v-html="row.item.Parts.find((part) => part.TargetId == participant.UserId).Email.HtmlContent" />

                </template>
            </fp-panel-card>
            <!-- SMS sablon -->
            <fp-panel-card :closedCard="true" v-if="row.item.Parts.find((part) => part.TargetId == participant.UserId).Sms"
                style="margin: 10px 0px"
                :title="$t('partner.participantList.communicationTab.sms')">
                <!-- Sablon típusa -->
                <template #title>
                    <b-icon style="margin: 0px 5px"
                            icon="chat-square-text-fill"></b-icon>
                    {{ $t("partner.participantList.communicationTab.sms") }}
                </template>
                <!-- Sablon adatok -->
                <template #content>
                    <div class="contenttext" 
                         v-if="row.item.Parts.find((part) => part.TargetId == participant.UserId).Sms.Result.Code != 0"
                         v-html="row.item.Parts.find((part) => part.TargetId == participant.UserId).Sms.Result.Message" />
                    <!-- SMS üzenet -->
                    <fp-text-area :label="$t('partner.participantList.communicationTab.smsText')"
                                  :disabled="true"
                                  v-model="row.item.Parts.find((part) => part.TargetId == participant.UserId ).Sms.Text" />

                </template>
            </fp-panel-card>
            <!-- PUSH sablon -->
            <fp-panel-card :closedCard="true" v-if="row.item.Parts.find((part) => part.TargetId == participant.UserId).Push"
                style="margin: 10px 0px"
                :title="$t('partner.participantList.communicationTab.push')">
                <!-- Sablon típusa -->
                <template #title>
                    <b-icon style="margin: 0px 5px" icon="phone-vibrate"></b-icon>
                    {{ $t("partner.participantList.communicationTab.push") }}
                </template>
                <!-- Sablon adatok -->
                <template #content>
                    <div class="contenttext" 
                         v-if="row.item.Parts.find((part) => part.TargetId == participant.UserId).Sms.Result.Code != 0"
                         v-html="row.item.Parts.find((part) => part.TargetId == participant.UserId).Push.Result.Message" />
                    <!-- Push címe -->
                    <fp-input :label="$t('partner.participantList.communicationTab.pushTitle')"
                              :disabled="true"
                              v-model="row.item.Parts.find((part) => part.TargetId == participant.UserId).Push.Title" />
                    <!-- Push üzenet -->
                    <fp-text-area :label="$t('partner.participantList.communicationTab.pushText')"
                                  :disabled="true"
                                  v-model="row.item.Parts.find((part) => part.TargetId == participant.UserId).Push.Body" />
                    <!-- Push képe -->
                    <fp-input :label="$t('partner.participantList.communicationTab.pushPhoto')"
                              :disabled="true"
                              v-model="row.item.Parts.find((part) => part.TargetId == participant.UserId).Push.ImageUrl" />
                    <div>
                        <img width="200px"
                             v-if="row.item.Parts.find((part) => part.TargetId == participant.UserId).Push.ImageUrl"
                             :src="row.item.Parts.find((part) => part.TargetId == participant.UserId).Push.ImageUrl" />
                    </div>

                </template>
            </fp-panel-card>
        </template>

    </fp-table-list>
  </div>
</template>
<script>
import moment from "moment";
import TemplateList from "./tabs-components/Communication/TemplateList.vue";
import { NotificationLogic } from "../../../Logic/Backend/notification";

export default {
  components: { TemplateList },
  data() {
    return {
      filteredCommunicationHistory: null,
      communicationHistory: null,
      communicationHistoryFields: [
        //{
        //   key: "ID",
        //   label: "Id",
        //},
        {
          key: "CreatedAt",
          label: this.$t("partner.participantList.communicationTab.sendTime"),
          sortable: true,
        },
        {
          key: "Types",
          label: this.$t(
            "partner.participantList.communicationTab.communicationTypes"
          ),
        },
        {
          key: "Operations",
          label: "",
        },
        //{
        //  key: "Resend",
        //    label: this.$t("partner.participantList.communicationTab.resend"),
        //},
      ],
      openDiary: false,
      selectedCommunication: {},
      openNewMessage: false,
      openTemplate: false,
      filter: null,
      addDateFilter: null,
    };
  },
  props: {
    participant: Object,
    project: Object,
  },
  watch: {
    async participant() {
      await this.getSentNotifications();
    },
  },
  methods: {
    filterList() {
      this.filteredCommunicationHistory = this.communicationHistory;
      /* if (this.filter) {
        this.filteredCommunicationHistory = this.$filterList(
          this.filter,
          this.communicationHistory,
          [
            TODO
          ]
        );
      } */
      if (this.addDateFilter) {
        this.filteredCommunicationHistory = this.filteredCommunicationHistory.filter(
          (noti) => {
            if (this.addDateFilter.from && this.addDateFilter.to) {
              return moment(noti.CreatedAt).isBetween(
                this.addDateFilter.from,
                this.addDateFilter.to,
                undefined,
                "[]"
              );
            } else if (this.addDateFilter.from) {
              return moment(noti.CreatedAt).isSameOrAfter(
                moment(this.addDateFilter.from)
              );
            } else if (this.addDateFilter.to) {
              return moment(noti.CreatedAt).isSameOrBefore(
                moment(this.addDateFilter.to)
              );
            }
            return true;
          }
        );
      }
    },
      async getSentNotifications() {
      const getNotiResponse = await NotificationLogic.getSentNotification({
        SenderId: this.$loggedUser.UserId,
      });  
          
      if (!getNotiResponse.Code) {
        this.communicationHistory = this.filteredCommunicationHistory = this.filterUser(getNotiResponse);
      } else {
        this.$bvToast.toast(getNotiResponse.Message, {
          title: this.$t("requestResponse.communication.errorMessagesList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
        this.communicationHistory = this.filteredCommunicationHistory = [];        
      }
    },
      filterUser(elements) {
          return elements.filter((e) => e.Parts.some((part) => part.TargetId === this.participant.UserId));
      },
      getStatus(item) {

          if (item.Result.Code == 0) {
              return "success";
          }
          else {
              return "danger";
          }
   
      },
  },
  async mounted() {
    await this.getSentNotifications();
        },
  
};
</script>
<style scoped>
    .filter-operation {
        margin-right: 10px;
    }
    .content {
        background-color: white;  
        padding: 20px;
    }
    .contenttext {
        padding: 5px;
        font-size: 12px;
        color:red;
        font-weight:bold;
    }  
</style>
